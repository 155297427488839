<template>
  <canvas ref="chartRef" :width="width" :height="height"></canvas>
</template>

<script>
import { ref, onMounted, onBeforeUnmount, watch } from 'vue'
import merge from 'lodash/merge'
import Chart from 'chart.js'
import 'chartjs-plugin-colorschemes/src/plugins/plugin.colorschemes'
import { Classic20 } from 'chartjs-plugin-colorschemes/src/colorschemes/colorschemes.tableau'
import 'chartjs-plugin-style';

export default {
  name: 'charts-chart',

  props: {
    type: {
      type: String,
      default: 'line',
    },
    width: {
      type: Number,
      default: 400,
    },
    height: {
      type: Number,
      default: 400,
    },
    labels: {
      type: Array
    },
    data: {
      type: Array,
    },
    options: {
      type: Object,
      default: () => ({})
    }
  },

  setup(props) {
    const defaultOptions = {
      plugins: {
        colorschemes: {
          fillAlpha: 1,
          scheme: Classic20
        }
      },
      maintainAspectRatio: false,

      scales: {
        xAxes: [{
          distribution: 'series',
          bounds: 'ticks',
          offset: false,
          gridLines: {
            display: true,
          },
          ticks: {
            reverse: false,
            fontColor: 'rgba(24, 45, 63, 1)',
          },
        }],
        yAxes: [{
          // offset: false,
          gridLines: {
            drawBorder: true,
            // tickMarkLength: 0,
            color: 'rgba(24, 45, 63, 0.05)',
            zeroLineColor: 'rgba(24, 45, 63, 0.05)'
          },
          ticks: {
            fontColor: 'rgba(24, 45, 63, 1)',
            padding: 4
          }
        }]
      },

      elements: {
        point: {
          borderWidth: 0,
        }
      },

      legend: {
        position: 'bottom',
        labels: {
          usePointStyle: true,
          fontSize: 13,
          fontStyle: '600',
          fontColor: 'rgba(24, 45, 63, 1)',
          boxWidth: 6,
          padding: 16
        }
      },

      tooltips: {
        mode: 'index',
        backgroundColor: 'rgba(255, 255, 255, 0.95)',
        titleFontColor: 'rgba(24, 45, 63, 0.5)',
        titleFontStyle: 'normal',
        titleMarginBottom: 8,
        bodyFontSize: 14,
        bodyFontColor: '#182D3F',
        footerFontStyle: 'bold',
        footerFontSize: 14,
        footerFontColor: '#182D3F',
        xPadding: 16,
        yPadding: 16,
        caretSize: 6,
        cornerRadius: 4,
        displayColors: false,
        shadowOffsetX: 0,
        shadowOffsetY: 0,
        shadowBlur: 15,
        shadowColor: 'rgba(0, 0, 0, 0.1)',
      }
    }
    Chart.defaults.global.defaultFontFamily = "'Source Sans Pro'";

    let chartRef = ref()
    let chart = null

    const render = (isUpdate = false) => {
      chart = new Chart(chartRef.value.getContext('2d'), {
        type: props.type,
        data: {
            labels: JSON.parse(JSON.stringify(props.labels)),
            datasets: JSON.parse(JSON.stringify(props.data)),
        },
        options: merge(defaultOptions, props.options, isUpdate ? { animation: { duration: 0 } } : {}),
        plugins: []
      })
    }

    onMounted(() => {
      render()
    })

    watch(
      () => props.data,
      () => {
        chart.destroy()
        render(true)
      }
    )

    onBeforeUnmount(() => {
      chart.destroy()
    })

    return {
      chartRef
    }
  }
}
</script>
