<template>
  <slot v-if="$slots.default" v-bind:open-picker="() => openPicker" />
  <div class="relative w-full" v-click-away="closePicker">

    <template v-if="!$slots.default">
      <button
        v-if="asButton"
        type="button"
        class="flex-row items-center text-big-stone font-semibold text-sm px-2 py-2 rounded-l-full rounded-r-full focus:outline-none focus:ring-2 hover:bg-sail hover:bg-opacity-50 focus:ring-inset focus:ring-blumine transition duration-150 hidden md:flex"
        @click="togglePicker"
      >
        <span>
          {{ date.formatFromTo(modelValue.from, 'YYYY-MM-DD', 'MMM DD, YYYY') }}
        </span>
        <span class="font-bold px-1">&dash;</span>
        <span>
          {{ date.formatFromTo(modelValue.to, 'YYYY-MM-DD', 'MMM DD, YYYY') }}
        </span>
        <span>
          <svg class="w-5 h-5 text-big-stone" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="currentColor"><path d="M0 0h24v24H0z" fill="none"/><path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z"/></svg>
        </span>
      </button>

      <div
        class="flex"
        :class="{ 'md:hidden': asButton }"
        @click="openPicker"
      >
        <div class="flex w-1/2 flex-col">
          <v-label for="dateFrom">Date from</v-label>
          <input id="dateFrom" type="date" v-model="modelValue.from" @input="$emit('update:modelValue', { from: $event.target.value, to: modelValue.to })" class="rounded-l-md border-r-0 text-big-stone px-2 border-gray-300 focus:ring-0 focus:outline-none focus:border-gray-300 focus:shadow-none" />
        </div>
        <div class="flex w-1/2 flex-col">
          <v-label for="dateTo">Date to</v-label>
          <input id="dateTo" type="date" v-model="modelValue.to" @input="$emit('update:modelValue', { from: modelValue.from, to: $event.target.value })" class="rounded-r-md border-l-0 text-big-stone border-gray-300 px-2 focus:ring-0 focus:outline-none focus:border-gray-300 focus:shadow-none" />
        </div>
      </div>
    </template>

    <button v-if="withRangeSelector" class="font-semibold text-sm text-shakespear md:hidden mt-2" @click="state.showRanges = !state.showRanges">+ <span class="underline">Show Ranges</span></button>
    <div class="flex space-x-2 my-2 md:hidden" v-show="state.showRanges && withRangeSelector">
      <div class="flex flex-col space-y-2">
        <button @click="setPeriod('this-week')" class="lowercase rounded-full px-3 py-1 text-sm border border-big-stone text-big-stone focus:bg-blumine focus:text-white focus:outline-none">This Week</button>
        <button @click="setPeriod('last-week')" class="lowercase rounded-full px-3 py-1 text-sm border border-big-stone text-big-stone focus:bg-blumine focus:text-white focus:outline-none">Last Week</button>
      </div>
      <div class="flex flex-col space-y-2">
        <button @click="setPeriod('this-month')" class="lowercase rounded-full px-3 py-1 text-sm border border-big-stone text-big-stone focus:bg-blumine focus:text-white focus:outline-none">This Month</button>
        <button @click="setPeriod('previous-month')" class="lowercase rounded-full px-3 py-1 text-sm border border-big-stone text-big-stone focus:bg-blumine focus:text-white focus:outline-none">Previous Month</button>
        <button @click="setPeriod('next-month')" class="lowercase rounded-full px-3 py-1 text-sm border border-big-stone text-big-stone focus:bg-blumine focus:text-white focus:outline-none">Next Month</button>
      </div>
      <div class="flex flex-col space-y-2">
        <button @click="setPeriod('this-year')" class="lowercase rounded-full px-3 py-1 text-sm border border-big-stone text-big-stone focus:bg-blumine focus:text-white focus:outline-none">This Year</button>
        <button @click="setPeriod('previous-year')" class="lowercase rounded-full px-3 py-1 text-sm border border-big-stone text-big-stone focus:bg-blumine focus:text-white focus:outline-none">Previous Year</button>
        <button @click="setPeriod('next-year')" class="lowercase rounded-full px-3 py-1 text-sm border border-big-stone text-big-stone focus:bg-blumine focus:text-white focus:outline-none">Next Year</button>
      </div>
    </div>

    <div
      class="hidden md:flex absolute bg-white shadow-lg border-transparent mt-3 border rounded px-2 z-50"
      :class="{ 'left-3/4 transform -translate-x-3/4': asButton }"
      v-show="state.isPickerOpen"
    >
      <div class="w-36 flex flex-col p-4 border-r space-y-2 text-center" v-if="withRangeSelector">
        <button type="button" class="rounded-md py-1 hover:bg-sail hover:bg-opacity-50" @click="setPeriod('this-week')">This Week</button>
        <button type="button" class="rounded-md py-1 hover:bg-sail hover:bg-opacity-50" @click="setPeriod('last-week')">Last Week</button>
        <button type="button" class="rounded-md py-1 hover:bg-sail hover:bg-opacity-50" @click="setPeriod('this-month')">This Month</button>
        <button type="button" class="rounded-md py-1 hover:bg-sail hover:bg-opacity-50" @click="setPeriod('previous-month')">Previous Month</button>
        <button type="button" class="rounded-md py-1 hover:bg-sail hover:bg-opacity-50" @click="setPeriod('next-month')">Next Month</button>
        <button type="button" class="rounded-md py-1 hover:bg-sail hover:bg-opacity-50" @click="setPeriod('this-year')">This Year</button>
        <button type="button" class="rounded-md py-1 hover:bg-sail hover:bg-opacity-50" @click="setPeriod('previous-year')">Previous Year</button>
        <button type="button" class="rounded-md py-1 hover:bg-sail hover:bg-opacity-50" @click="setPeriod('next-year')">Next Year</button>
      </div>
      <div class="hidden md:flex">
        <input class="hidden" type="date" id="picker">
      </div>
    </div>
  </div>
</template>

<script>

import { onBeforeUnmount, reactive, watch } from 'vue'
import flatpickr from 'flatpickr'
import 'flatpickr/dist/flatpickr.css'
import { directive } from 'vue3-click-away'
import date from '@/helpers/date'
import VLabel from '@/components/form/Label'

export default {
  name: 'DateRangePicker',

  directives: {
    ClickAway: directive,
  },

  components: {
    VLabel,
  },

  props: {
    'model-value': {},
    submit: {},
    withRangeSelector: {
      type: Boolean,
      default: true
    },
    maxRange: {
      type: Number,
      default: null,
    },
    maxDate: {
      type: String,
      default: 'today',
    },
    asButton: {
      type: Boolean,
      default: false
    }
  },

  emits: ['submit', 'update:modelValue'],

  setup(props, { emit }) {
    const state = reactive({
      isPickerOpen: false,
      showRanges: false,
    })
    let picker = null

    const togglePicker = () => state.isPickerOpen = !state.isPickerOpen
    const openPicker = () => state.isPickerOpen = true
    const closePicker = () => state.isPickerOpen = false

    const setDate = (from, to) => {
      picker ? picker.setDate([from.format('YYYY-MM-DD'), to.format('YYYY-MM-DD')]) : null
      emit('update:modelValue', {
        from: from.format('YYYY-MM-DD'),
        to: to.format('YYYY-MM-DD'),
      })
      emit('submit')
    }

    const setPeriod = (period) => {
      let from = date.instance(date.now())
      let to = date.instance(date.now())
      const relativeDate = date.instance(props.modelValue.to)

      switch (period) {
        case 'previous-year':
          from = relativeDate.subtract(1, 'year').startOf('year')
          to = relativeDate.subtract(1, 'year').endOf('year')
          break;

        case 'next-year':
          from = relativeDate.add(1, 'year').startOf('year')
          to = relativeDate.add(1, 'year').endOf('year')
          break;

        case 'previous-month':
          from = relativeDate.subtract(1, 'month').startOf('month')
          to = relativeDate.subtract(1, 'month').endOf('month')
          break;

        case 'next-month':
          from = relativeDate.add(1, 'month').startOf('month')
          to = relativeDate.add(1, 'month').endOf('month')
          break;

        case 'last-week':
          from = from.subtract(1, 'week').startOf('week')
          to = to.subtract(1, 'week').endOf('week')
          break;

        case 'this-week':
          from = from.startOf('week')
          break;

        case 'this-month':
          from = from.startOf('month')
          break;

        case 'this-year':
          from = from.startOf('year')
          break;
      }

      setDate(from, to)
      picker ? picker.close() : null
    }

    watch(
      () => [props.modelValue.from, props.modelValue.to],
      () => picker ? picker.setDate([date.instance(props.modelValue.from).format('YYYY-MM-DD'), date.instance(props.modelValue.to).format('YYYY-MM-DD')]) : null
    )

    watch(
      () => state.isPickerOpen,
      () => {
        picker = flatpickr(document.getElementById('picker'), {
          mode: 'range',
          maxDate: props.maxDate,
          inline: true,
          altFormat: 'YYYY-MM-DD - YYYY-MM-DD',
          showMonths: 2,
          defaultDate: [props.modelValue.from, props.modelValue.to],
          onClose(selectedDates) {
            if (! props.maxRange && selectedDates[0] && selectedDates[1]) {
              closePicker()
              setDate(date.instance(selectedDates[0]), date.instance(selectedDates[1]))
            }
          },
          onChange(selectedDates, dateStr, instance) {
            if (selectedDates.length > 1) {
              let range = instance.formatDate(selectedDates[1], 'U') - instance.formatDate(selectedDates[0], 'U');
              range = range / 86400;

              if (props.maxRange && range > props.maxRange) {
                alert(`Maximal allowed range is ${props.maxRange} days`)
                // instance.clear()
              } else if (selectedDates[0] && selectedDates[1]) {
                setDate(date.instance(selectedDates[0]), date.instance(selectedDates[1]))

                closePicker()
              }
            }
          },
        })
      }
    )

    onBeforeUnmount(() => picker ? picker.destroy() : null)

    return {
      state,
      openPicker,
      closePicker,
      togglePicker,
      setPeriod,
      date,
    }
  }
}
</script>
