<template>
  <div>
    <div class="flex justify-between items-center">
      <h2 class="mb-1 font-semibold uppercase text-sm">{{ title }}</h2>
    </div>
    <div class="bg-white rounded-md shadow-sm mt-1">
      <div v-if="isLoading" class="flex flex-col justify-center items-center" style="min-height: 432px;">
        <icon-loading class="text-blumine opacity-50 hover w-5 h-5" />
      </div>
      <div v-else>
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
import { IconLoading } from '@/components/icons'

export default {
  name: 'reports-chart-card',

  components: {
    IconLoading,
  },

  props: ['title', 'isLoading'],
}
</script>
