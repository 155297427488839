<template>
  <label :for="forEl" class="block text-sm font-semibold text-blumine mb-1">
    <slot /> <span v-if="required" class="text-shakespear">*</span>
  </label>
</template>

<script>
export default {
  props: {
    forEl: {
      type: String,
      default: ''
    },
  }
}
</script>
